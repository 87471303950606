import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Empty, Tooltip, message } from "antd";
import { toast, ToastContainer } from "react-toastify";
import noProfile from "../../../../images/noProfile.jpg";
import { useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { ConfigProvider, Select } from "antd";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../store/actions/AuthActions";

const Users = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  const [messageApi, contextHolder] = message.useMessage();
  let Navigate = useNavigate();
  let { id } = useParams();
  const handleCopyClick = (email) => {
    const textarea = document.createElement("textarea");
    textarea.value = email;

    document.body.appendChild(textarea);

    textarea.select();

    document.execCommand("copy");

    document.body.removeChild(textarea);
    toast.success("✅ Copied to clipboard!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [Users, setUsers] = useState([]);
  const [ShowUsers, setShowUsers] = useState(false);
  const [User0, setUser0] = useState([]);
  const [Load, setLoad] = useState(false);

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  const getUsers = () => {
    setLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `client/users/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.users) {
          setUsers(result?.users);
          setLoad(false);
        } else if (
          result.message === "Unauthorized!" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        } else {
          setLoad(false);
        }
      })
      .then(() => {
        setUser0(Users[0]);
        console.log(User0);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    getUsers();
  }, [accessToken.accessToken, id]);

  const [client, setClient] = useState({});

  const getClient = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `client/${Users[0]?.ClientId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setClient(result?.client);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    getClient();
  }, [accessToken.accessToken, accessToken.client.id, Users]);

  const handleDisable = (id, data) => {
    if (
      data.status !== "active" &&
      client?.ActiveusersCount >= client?.numberOfUsers
    ) {
      swal(
        "Failed",
        `User  limit exceeded, can not enable ${data.fullname}`,
        "error"
      );
    } else {
      let permission = JSON.parse(data.permissions);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      const raw = JSON.stringify({
        viewAllInvoices: permission[0],
        editAllInvoices: permission[1],
        disable: data.status === "active" ? true : false,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `users/update-permission/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.message === "Successfully Updated user") {
            messageApi.open({
              type: data.status === "active" ? "error" : "success",
              content:
                data.status === "active" ? "User Disabled!" : "User Enabled",
            });
            getUsers();
          }
        })
        .catch((error) => console.error(error));
    }
  };

  //   API
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [conPass, setConPass] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("sub_user");
  const [permission, setPermission] = useState("[0,0]");
  //   Error handlers
  const [Error, setError] = useState(false);

  //   Loaders
  const [Loading, setLoading] = useState(false);

  const CreateUser = () => {
    setError("");
    if (fullName === "") {
      setError("name is required");
    } else if (email === "") {
      setError("email is required");
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError("Invalid Email");
    } else if (mobile === "") {
      setError("mobile is required");
    } else if (mobile.length > 11 || mobile.length < 8) {
      setError("mobile number must be between 8 to 11 digits only.");
    } else if (pass.length < 6) {
      setError("Pass must be at least 6 characters");
    } else if (pass !== conPass) {
      setError("Password doesn't match");
    } else if (role === "") {
      setError("Provide user role");
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      const raw = JSON.stringify({
        fullname: fullName,
        email: email,
        password: pass,
        confirmPassword: conPass,
        mobileNumber: mobile,
        role: role,
        clientId: id,
        permissions: permission,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          "users/create-new-user",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result.errors) {
            setError(result.errors[0]?.msg);
            setLoading(false);
          } else if (result.message === "User creation limit exceeded") {
            swal("Failed", "User creation limit exceeded", "error");
            setLoading(false);
          } else {
            swal(
              "Created!",
              `${fullName.replace("-", " ")}'s account has been created`,
              "success"
            ).then(() => {
              getUsers();
              setLoading(false);
              setShowUsers(false);
            });
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const handleChange = (value) => {
    setRole(value);
    if (value === "client_admin") setPermission("[1,1]");
  };

  return (
    <>
      {Load ? (
        <div className="flex justify-center items-center w-full h-[60vh]">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          {contextHolder}
          <div className="flex justify-end my-4">
            {/* {console.log(Users)}
            {console.log("No. User: ", client?.numberOfUsers)}
            {console.log("User Len: ", Users?.length)}
            {console.log(Users?.length < client?.numberOfUsers)} */}
            {(client?.ActiveusersCount < Users?.length ||
              Users?.length < client?.numberOfUsers) &&
              client?.ActiveusersCount != client?.numberOfUsers && (
                <button
                  className="bg-[#0aa1dd] p-3 px-5 rounded text-white font-semibold text-base flex gap-2 items-center animatedView"
                  onClick={() => setShowUsers(true)}
                >
                  <FaPlus />
                  <span>Add User</span>
                </button>
              )}
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"> */}
          {ShowUsers === false ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-10 flex-wrap animatedView">
              {Users && Users.length > 0 ? (
                Users.map((el, i) => {
                  //   let permission = JSON.parse(el.permissions);
                  return (
                    <div
                      className={`p-7 rounded-2xl w-full animatedView relative bg-white`}
                      key={i}
                    >
                      {el.role === "client_admin" ? (
                        <div className="absolute top-0 right-0 p-2 userBg rounded-tr-2xl rounded-tl-none rounded-br-none rounded-bl-2xl">
                          <p className="font-semibold text-[#fff] tracking-wider">
                            Company Admin
                          </p>
                        </div>
                      ) : (
                        <div className="absolute top-0 right-0 py-2 px-4 bg-amber-500 rounded-tr-2xl rounded-tl-none rounded-br-none rounded-bl-2xl">
                          <p className="font-semibold text-[#fff] tracking-wider">
                            Sub User
                          </p>
                        </div>
                      )}
                      <div className="flex gap-4 items-center">
                        <div>
                          <img
                            src={noProfile}
                            alt=""
                            className="w-28 object-cover p-1 border border-white"
                            style={{
                              borderTopLeftRadius: 10,
                              borderBottomRightRadius: 10,
                            }}
                          />
                        </div>
                        {/* Name Email Number */}
                        <div>
                          <h6
                            className={`text-sm cursor-pointer`}
                            onClick={() => Navigate(-1)}
                          >
                            User {i + 1}
                          </h6>
                          <h6 className={`font-semibold tracking-wider`}>
                            {el.fullname}
                          </h6>
                          <div className="flex items-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                            >
                              <path
                                d="M15.7285 9.43725C15.7282 7.76874 15.0652 6.16867 13.8854 4.98886C12.7056 3.80904 11.1055 3.14609 9.437 3.14575V4.40405C10.4324 4.40428 11.4053 4.69952 12.233 5.25248C13.0606 5.80544 13.7058 6.59131 14.087 7.51079C14.34 8.12155 14.4702 8.77616 14.4702 9.43725H15.7285ZM3.14551 8.17895V5.0332C3.14551 4.86634 3.21179 4.70631 3.32978 4.58832C3.44777 4.47034 3.6078 4.40405 3.77466 4.40405H6.9204C7.08726 4.40405 7.24729 4.47034 7.36528 4.58832C7.48327 4.70631 7.54955 4.86634 7.54955 5.0332V7.5498C7.54955 7.71666 7.48327 7.87668 7.36528 7.99467C7.24729 8.11266 7.08726 8.17895 6.9204 8.17895H5.66211C5.66211 9.51383 6.19239 10.794 7.13629 11.738C8.0802 12.6819 9.36041 13.2121 10.6953 13.2121V11.9538C10.6953 11.787 10.7616 11.627 10.8796 11.509C10.9976 11.391 11.1576 11.3247 11.3244 11.3247H13.841C14.0079 11.3247 14.1679 11.391 14.2859 11.509C14.4039 11.627 14.4702 11.787 14.4702 11.9538V15.0996C14.4702 15.2664 14.4039 15.4265 14.2859 15.5445C14.1679 15.6625 14.0079 15.7287 13.841 15.7287H10.6953C6.52593 15.7287 3.14551 12.3483 3.14551 8.17895Z"
                                fill={`#0aa1dd`}
                              />
                              <path
                                d="M12.9239 7.99272C13.1138 8.45063 13.2115 8.94152 13.2114 9.43725H12.079C12.079 9.09022 12.0107 8.74657 11.878 8.42594C11.7452 8.10531 11.5506 7.81398 11.3052 7.56859C11.0598 7.3232 10.7685 7.12856 10.4478 6.9958C10.1272 6.86303 9.78356 6.79474 9.43652 6.79482V5.66235C10.1831 5.66239 10.9129 5.88381 11.5337 6.29861C12.1544 6.71341 12.6382 7.30297 12.9239 7.99272Z"
                                fill={`#0aa1dd`}
                              />
                              <rect
                                x="0.393218"
                                y="0.393218"
                                width="18.088"
                                height="18.088"
                                rx="3.53896"
                                stroke={`#0aa1dd`}
                                strokeWidth="0.786437"
                              />
                            </svg>
                            <h6 className={`text-[#333] text-sm mt-1`}>
                              {el.mobileNumber}
                            </h6>
                          </div>
                          <div className="flex items-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                            >
                              <path
                                d="M15.7295 13.495V5.63061C15.7295 4.90534 15.2338 4.31989 14.6199 4.31989H4.2561C3.64211 4.31989 3.14648 4.90534 3.14648 5.63061V13.495C3.14648 14.2202 3.64211 14.8057 4.2561 14.8057H14.6199C15.2338 14.8057 15.7295 14.2202 15.7295 13.495ZM14.7604 5.53449C15.0045 5.82285 14.8714 6.11995 14.7382 6.2685L11.7349 9.5191L14.6199 13.0668C14.7086 13.1891 14.7678 13.3814 14.6642 13.5125C14.5681 13.6523 14.3462 13.6435 14.25 13.5561L11.0173 10.2968L9.43428 12.0007L7.85863 10.2968L4.62597 13.5561C4.5298 13.6435 4.30788 13.6523 4.21171 13.5125C4.10815 13.3814 4.16733 13.1891 4.2561 13.0668L7.14108 9.5191L4.13774 6.2685C4.00458 6.11995 3.87143 5.82285 4.11554 5.53449C4.35966 5.24613 4.61117 5.38594 4.8183 5.59566L9.43428 9.99971L14.0577 5.59566C14.2648 5.38594 14.5163 5.24613 14.7604 5.53449Z"
                                fill={`#0aa1dd`}
                              />
                              <rect
                                x="0.393218"
                                y="0.518707"
                                width="18.088"
                                height="18.088"
                                rx="3.53896"
                                stroke={`#0aa1dd`}
                                strokeWidth="0.786437"
                              />
                            </svg>
                            <div
                              className={`text-[#333] text-sm mt-1 text-wrap cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis w-full`}
                              onClick={() => handleCopyClick(el.email)}
                            >
                              <Tooltip
                                title="Copy to clipboard"
                                color="#0aa1dd"
                              >
                                <span>{el.email}</span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      {el.id !== accessToken?.user?.id && (
                        <div className="flex flex-col gap-[22px] mt-[24px]">
                          <div className="flex items-center justify-between mt-[42px]">
                            <div>
                              <p className="font-[500] text-sm text-red-500">
                                Disable
                              </p>
                              <figcaption className={`text-[#333] text-[10px]`}>
                                Disable this user until turn on.
                              </figcaption>
                            </div>
                            <div>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                checked={el.status === "active" ? false : true}
                                onChange={() => handleDisable(el.id, el)}
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="bg-white h-96 w-full rounded-2xl flex justify-center items-center">
                  <Empty className="text-red-500 text-sm" />
                </div>
              )}
            </div>
          ) : (
            <div className="flex justify-start md:justify-center animatedView">
              <div className="w-full md:w-1/2">
                <div className="flex ml-14">
                  <h1 className="text-[#fff] bg-[#0aa1dd] rounded-md p-3 m-2 ml-5 z-[999] text-xl">
                    Create User
                  </h1>
                </div>
                <div className="bg-white p-3 rounded-[20px] flex items-center relative -mt-12 animatedView">
                  <div className="mx-5 w-full flex flex-col gap-3 animatedView">
                    {/* Full Name */}
                    <div className="relative mt-5">
                      <label htmlFor="document" className="font-semibold">
                        Name: <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        placeholder="enter customer's name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                    {/* Email */}
                    <div className="relative mt-2">
                      <label htmlFor="document" className="font-semibold">
                        Email:
                      </label>
                      <input
                        type="email"
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        placeholder="enter customer's email (optional)"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {/* Mobile */}
                    <div className="relative mt-2">
                      <label htmlFor="document" className="font-semibold">
                        Mobile Number: <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        placeholder="enter customer's mobile number..."
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                    {/* Pass */}
                    <div className="relative mt-2">
                      <label htmlFor="document" className="font-semibold">
                        Password:
                      </label>
                      <input
                        type="password"
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        placeholder="enter password..."
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                      />
                    </div>
                    {/* ConPass */}
                    <div className="relative mt-2">
                      <label htmlFor="document" className="font-semibold">
                        Confirm Password:
                      </label>
                      <input
                        type="password"
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        placeholder="confirm your password..."
                        value={conPass}
                        onChange={(e) => setConPass(e.target.value)}
                      />
                    </div>
                    {/* Role */}
                    <div className="relative mt-2">
                      <ConfigProvider
                        theme={{
                          token: {
                            controlHeight: 36,
                            borderRadius: "0px 6px 6px 0px",
                            colorBorder: "rgba(255,255,255,0)",
                            colorPrimaryHover: "#0aa1dd",
                            lineWidth: 0,
                            fontSize: 12,
                          },
                        }}
                      >
                        <label htmlFor="document" className="font-semibold">
                          Role:
                        </label>
                        <Select
                          defaultValue={role}
                          className="w-full border rounded-lg"
                          placeholder="select role"
                          onChange={handleChange}
                          options={[
                            {
                              value: "client_admin",
                              label: "Client Admin",
                            },
                            {
                              value: "sub_user",
                              label: "Sub User",
                            },
                          ]}
                        />
                      </ConfigProvider>
                    </div>
                    {Error && (
                      <div className="text-xs m-1 text-red-500 animatedView">
                        {Error}
                      </div>
                    )}
                    {/* Button */}
                    <div className="flex justify-end  gap-3 mt-2">
                      <button
                        className="p-3 bg-gray-200 rounded-md text-gray-900 font-semibold px-3 flex items-center gap-3"
                        onClick={() => setShowUsers(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="p-3 bg-[#0aa1dd] rounded-md text-white px-3 flex items-center gap-3"
                        onClick={CreateUser}
                      >
                        {Loading ? (
                          <Spinner animation="border" variant="light"></Spinner>
                        ) : (
                          <>
                            Create User
                            <span>
                              <i className="bi bi-check2-all"></i>
                            </span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default Users;
