import { Empty, ConfigProvider, Pagination } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../store/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const PaymentHistory = () => {
  let { id } = useParams();
  let Navigate = useNavigate();
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));

  // Get Payment History
  const [PaymentHistory, setPaymentHistory] = useState([]);
  const [Page, setPage] = useState(1);
  const [Limit, setLimit] = useState(10);
  const [TotalCount, setTotalCount] = useState();

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
  };

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  const getPaymentHistory = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
        `client-payments?clientId=${id}&page=${Page}&limit=${Limit}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(">PreFilled", result.requirement);
        if (result.clientPayments) {
          setPaymentHistory(result.clientPayments);
          setTotalCount(result.totalRecords);
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getPaymentHistory();
  }, [Page, Limit]);

  return (
    <div>
      <div className="relative overflow-x-auto transition-all duration-500 ease-in-out">
        <h3>Payment History</h3>
        <table className="table text-gray-400 border-separate space-y-6 text-sm">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className={`px-3 py-4 text-left `}>Sr No</th>
              <th className={`px-3 py-4 text-left `}>Payment Ref</th>
              <th className={`px-3 py-4 text-left `}>Duration</th>
              <th className={`px-3 py-4 text-left `}>Amount</th>
              <th className={`px-3 py-4 text-left `}>Start Date</th>
              <th className={`px-3 py-4 text-left `}>End Date</th>
              <th className={`px-3 py-4 text-left `}>Created Date</th>
            </tr>
          </thead>
          <tbody className="animatedView">
            {PaymentHistory && PaymentHistory?.length > 0 ? (
              PaymentHistory.map((el, i) => {
                const serialnumber = (Page - 1) * Limit + (i + 1);
                return (
                  <tr
                    className="bg-transparent border-2 border-black lg:text-black text-base"
                    key={i}
                  >
                    <td className="p-3">{serialnumber}</td>
                    <td className="p-3 font-medium capitalize text-center">
                      <button
                      // onClick={() => openInvoiceModal(el)}
                      >
                        {el.refNo}
                      </button>
                    </td>
                    <td className="p-3">{el.durationInMonths} months</td>
                    <td className="p-3">{el.amount}</td>
                    <td className="p-3">
                      {moment(el.startDate).format("DD-MM-YYYY, hh:mm a")}
                    </td>
                    <td className="p-3">
                      {moment(el.endDate).format("DD-MM-YYYY, hh:mm a")}
                    </td>
                    <td className="p-3">
                      {moment(el.createdAt).format("DD-MM-YYYY, hh:mm a")}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td>
                  <Empty />
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex justify-end py-3 border-t">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#0aa1dd",
              },
            }}
          >
            <Pagination
              defaultCurrent={Page}
              total={TotalCount}
              onChange={(val) => setPage(val)}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
