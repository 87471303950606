import React, { Fragment, useEffect, useState } from "react";
import green from "../../../../images/theme/green.png";
import red from "../../../../images/theme/red.png";
import orange from "../../../../images/theme/orange.png";
import purple from "../../../../images/theme/purple.png";
import { Dropdown, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import { HiOutlineMinus } from "react-icons/hi";
import { HiOutlinePlus } from "react-icons/hi";
import Plus from "../../../../images/icons8-plus-24.png";
import Minus from "../../../../images/icons8-minus-24.png";
import moment from "moment";
import {
  Empty,
  Progress,
  Tooltip,
  Pagination,
  ConfigProvider,
  DatePicker,
} from "antd";
import active from "./active.svg";
import deactive from "./Deactive.svg";
import companyLogo from "./company.svg";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
// import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../../store/actions/AuthActions";

const Company = () => {
  const handleCopyClick = (email) => {
    const textarea = document.createElement("textarea");
    textarea.value = email;

    document.body.appendChild(textarea);

    textarea.select();

    document.execCommand("copy");

    document.body.removeChild(textarea);
    toast.success("Copied to clipboard!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  let accessToken = JSON.parse(localStorage.getItem("userDetails"));

  const [Company, setCompany] = useState([]);
  const [Loading, setLoading] = useState(true);

  const [Paginate, setPaginate] = useState();
  const [Limit, setLimit] = useState(5);
  const [Page, setPage] = useState(1);

  const handlePaginate = (value, size) => {
    setPage(value);
    window.scroll(0, 0);
  };

  const [ShowFilters, setShowFilters] = useState(false);
  const [SubscriptionData, setSubscriptionData] = useState([]);
  const [SubscriptionID, setSubscriptionID] = useState("");
  const [ExpireDate, setExpireDate] = useState("");
  const [CreatedDate, setCreatedDate] = useState("");
  const [Refersh, setRefersh] = useState();
  // Expire Date
  const handleExpireChange = (date, dateString) => {
    setExpireDate(dateString);
  };

  const initialStartDate = moment().startOf("day");
  const initialEndDate = moment().endOf("day");

  const handleDateRangeChange = (event, picker) => {
    setCreatedDate(
      picker.startDate.format("MM-DD-YYYY") +
        "," +
        picker.endDate.format("MM-DD-YYYY")
    );
  };

  const handleExpDateRangeChange = (event, picker) => {
    setExpireDate(
      picker.startDate.format("MM-DD-YYYY") +
        "," +
        picker.endDate.format("MM-DD-YYYY")
    );
  };

  // Saearch Functionality
  const [SearchValue, setSearchValue] = useState("");

  const getSubscription = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "subscriptions/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setSubscriptionData(result.subscriptions);
      })
      .catch((error) => console.log("error", error));
  };

  const [searchLoad, setSearchLoad] = useState(false);
  const [ClientStatus, setClientStatus] = useState("");
  const [maxClientCount, setMaxClientCount] = useState();
  const [noOfUsers, setNoOfUsers] = useState([]);
  const [render, setRender] = useState(0);

  useEffect(() => {
    const getAllClients = setTimeout(() => {
      setSearchLoad(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `client?page=${Page}&limit=${Limit}&expiresOn=${ExpireDate}&createdAt=${CreatedDate}&subscriptionId=${SubscriptionID}&name=${SearchValue}&status=${ClientStatus}&search=${SearchValue}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          setCompany(result.clients);

          if (result.clients) {
            //setMaxClientCount(result.clients[0].numberOfUsers)
            setPaginate(result.total);
            // console.log("Companies:",Company)
            //sconsole.log(noOfUsers);
            setLoading(false);
            setSearchLoad(false);
            // Company.map((val)=>{setNoOfUsers(val.numberOfUsers)});
            // console.log(noOfUsers);
            // console.log(result?.clients?.name,result?.clients?.numberOfUsers);
            // Map the numberOfUsers from each client to the users state
            const usersArray = result.clients.map(
              (client) => client.numberOfUsers || 0
            ); // reverse()
            setNoOfUsers(usersArray);
            setNOULoaderM({ status: false });
            setNOULoaderP({ status: false });
          } else if (
            result.message === "Unauthorized!" ||
            result.message === "token expire"
          ) {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          }
        })
        .catch((error) => console.log("error", error));
    }, 2000);
    return () => clearTimeout(getAllClients);
  }, [
    accessToken.accessToken,
    Page,
    Limit,
    SubscriptionID,
    ExpireDate,
    CreatedDate,
    SearchValue,
    Refersh,
    ClientStatus,
    render,
    SearchValue,
  ]);
  // Debugging: Log the users state when it changes
  // useEffect(() => {
  //   console.log("No of Users", noOfUsers);
  // }, [noOfUsers]);

  useEffect(() => {
    getSubscription();
  }, []);

  const filteredData = SearchValue
    ? Company &&
      Company.filter(
        (item) =>
          (item?.name &&
            item?.name.toLowerCase().includes(SearchValue.toLowerCase())) || // Check if the name includes the search value
          (item?.Users[0]?.fullname &&
            item?.Users[0]?.fullname
              .toLowerCase()
              .includes(SearchValue.toLowerCase())) || // Check if the full name includes the search value
          (item?.companyName &&
            item?.companyName.toLowerCase().includes(SearchValue.toLowerCase())) // Check if the company name includes the search value
      )
    : Company;

  const disableAccount = (id) => {
    // e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `client/disable/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.message === "Client disabled") {
          swal("Disabled", result.message, "error").then(() => {
            setRefersh(1);
          });
        } else {
          swal("Something Went Wrong", result.message, "error");
        }
      })
      .catch((error) => console.error(error));
  };

  const enableAccount = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `client/activate/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.message === "Client activated") {
          swal("Activated", result.message, "success").then(() => {
            setRefersh(2);
          });
        } else {
          swal("Something Went Wrong", result.message, "error");
        }
      })
      .catch((error) => console.error(error));
  };

  const [NOULoaderP, setNOULoaderP] = useState("");
  const [NOULoaderM, setNOULoaderM] = useState("");
  const handleUpdateMinus = (id, currentNumberOfUsers) => {
    // Prevent decrementing if it is already at the minimum value (e.g., 0)
    if (currentNumberOfUsers <= 0) return;

    updateNumberOfUsers(id, currentNumberOfUsers - 1);
    setNOULoaderM({ status: true, id: id });
  };

  const handleUpdatePlus = (id, currentNumberOfUsers) => {
    setNOULoaderP({ status: true, id: id });
    updateNumberOfUsers(id, currentNumberOfUsers + 1);
  };

  const updateNumberOfUsers = (id, newNumberOfUsers) => {
    // setNOULoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const raw = JSON.stringify({ numberOfUsers: newNumberOfUsers });
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `client/update/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.message === "Successfully Updated") {
          setRender((prev) => prev + 1); // This will trigger the useEffect to re-fetch data
        } else {
          setNOULoaderM({ status: false });
          setNOULoaderP({ status: false });
        }
        // setNOULoader(false);
        // After successful update, refresh the data
      })
      .catch((error) => console.error(error));
  };

  return (
    <Fragment>
      {Loading ? (
        <div className="flex justify-center items-center h-[95vh] bg-white -m-10">
          <Spinner animation="border" variant="primary" className="mb-20" />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-3 mb-2 animatedView">
            <div className="flex justify-between">
              {/* Filter */}
              <div className="flex justify-end">
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={
                    ShowFilters
                      ? () => {
                          setShowFilters(false);
                          setCreatedDate("");
                          setExpireDate("");
                          setClientStatus("");
                          setSubscriptionID("");
                        }
                      : () => setShowFilters(true)
                  }
                >
                  <button
                    className={`border-[2px] p-1 ${
                      ShowFilters ? "border-red-500" : "border-[#0AA1DD]"
                    } rounded-md`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                    >
                      <path
                        d="M21.6425 0H0.330021C0.240051 0.00694095 0.156034 0.0476488 0.0948273 0.113956C0.033621 0.180262 -0.000246098 0.267263 2.12422e-05 0.3575V1.54C-0.000804896 1.6612 0.022474 1.78136 0.0685041 1.89348C0.114534 2.00561 0.182397 2.10746 0.268146 2.19312L8.51814 10.4431V17.3181L13.5025 19.8V10.4294L21.7525 2.17937C21.9103 2.00913 21.9986 1.78591 22 1.55375V0.3575C22 0.262685 21.9623 0.171754 21.8953 0.104709C21.8282 0.0376652 21.7373 0 21.6425 0Z"
                        fill={ShowFilters ? "rgb(239 68 68)" : "#0AA1DD"}
                      />
                    </svg>
                  </button>
                  <p
                    className={`${
                      ShowFilters ? "text-red-500" : "text-[#0AA1DD]"
                    } text-sm`}
                  >
                    {ShowFilters ? "Clear" : "Advance Filter"}
                  </p>
                </div>
              </div>
              {/* Search */}
              <div className="relative">
                <input
                  type="search"
                  name="search"
                  id=""
                  className="p-2.5 w-[200px] md:w-[300px] bg-white rounded-lg"
                  placeholder="search here..."
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                {searchLoad && (
                  <div className="absolute top-3 right-16">
                    <Spinner animation="border" size="sm" color="#0aa1dd" />
                  </div>
                )}
                <div className="absolute top-0 right-0 p-2.5 px-3 bg-[#0aa1dd] rounded-r-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                  >
                    <g
                      data-name="Layer 2"
                      fill="#ffffff"
                      className="color000 svgShape"
                    >
                      <path
                        d="m20.71 19.29-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"
                        data-name="search"
                        fill="#ffffff"
                        className="color000 svgShape"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            {ShowFilters && (
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-9 transition-transform duration-700  ease-in-out animatedView flex-wrap">
                {/* Subscriptions */}
                <div className="w-full flex flex-col">
                  <label
                    htmlFor="user"
                    className="text-[#555] text-sm font-semibold"
                  >
                    Subscription
                  </label>
                  <Form.Select
                    aria-label="Default select example"
                    style={{
                      outline: "none",
                      borderRadius: 5,
                      border: 0,
                      padding: 9,
                    }}
                    className="ring-0 focus:ring-transparent bg-white border-none rounded-[5px] text-[#A1A1A1]"
                    onChange={(e) => setSubscriptionID(e.target.value)}
                  >
                    <option value="">All Subscription</option>
                    {SubscriptionData &&
                      SubscriptionData.map((el, i) => {
                        return (
                          <option
                            value={el.id}
                            className="text-[#A1A1A1] py-3"
                            key={i}
                          >
                            {el.duration} Months
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>
                {/* Created At */}
                <div className="w-full flex flex-col">
                  <label
                    htmlFor="user"
                    className="text-[#555] text-sm font-semibold"
                  >
                    Created Date
                  </label>
                  <div className="relative">
                    <DateRangePicker
                      initialSettings={{
                        startDate: initialStartDate.format("MM/DD/YYYY"),
                        endDate: initialEndDate.format("MM/DD/YYYY"),
                      }}
                      onApply={handleDateRangeChange}
                    >
                      <button className="text-gray-700 bg-white font-[500] rounded-md p-2.5 w-full flex justify-between items-center gap-2">
                        {CreatedDate === ""
                          ? "DD-MM-YYYY"
                          : CreatedDate?.replace(",", " to ")}
                        {CreatedDate ? (
                          <span
                            className="h-5 w-5 bg-red-200 text-red-500 rounded-full flex justify-center items-center"
                            onClick={() => setCreatedDate("")}
                          >
                            X
                          </span>
                        ) : (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M13.6 1.6H12V0.8C12 0.587827 11.9157 0.384344 11.7657 0.234315C11.6157 0.0842854 11.4122 0 11.2 0C10.9878 0 10.7843 0.0842854 10.6343 0.234315C10.4843 0.384344 10.4 0.587827 10.4 0.8V1.6H5.6V0.8C5.6 0.587827 5.51571 0.384344 5.36569 0.234315C5.21566 0.0842854 5.01217 0 4.8 0C4.58783 0 4.38434 0.0842854 4.23431 0.234315C4.08429 0.384344 4 0.587827 4 0.8V1.6H2.4C1.76348 1.6 1.15303 1.85286 0.702944 2.30294C0.252856 2.75303 0 3.36348 0 4V13.6C0 14.2365 0.252856 14.847 0.702944 15.2971C1.15303 15.7471 1.76348 16 2.4 16H13.6C14.2365 16 14.847 15.7471 15.2971 15.2971C15.7471 14.847 16 14.2365 16 13.6V4C16 3.36348 15.7471 2.75303 15.2971 2.30294C14.847 1.85286 14.2365 1.6 13.6 1.6ZM14.4 13.6C14.4 13.8122 14.3157 14.0157 14.1657 14.1657C14.0157 14.3157 13.8122 14.4 13.6 14.4H2.4C2.18783 14.4 1.98434 14.3157 1.83431 14.1657C1.68429 14.0157 1.6 13.8122 1.6 13.6V8H14.4V13.6ZM14.4 6.4H1.6V4C1.6 3.78783 1.68429 3.58434 1.83431 3.43431C1.98434 3.28429 2.18783 3.2 2.4 3.2H4V4C4 4.21217 4.08429 4.41566 4.23431 4.56569C4.38434 4.71571 4.58783 4.8 4.8 4.8C5.01217 4.8 5.21566 4.71571 5.36569 4.56569C5.51571 4.41566 5.6 4.21217 5.6 4V3.2H10.4V4C10.4 4.21217 10.4843 4.41566 10.6343 4.56569C10.7843 4.71571 10.9878 4.8 11.2 4.8C11.4122 4.8 11.6157 4.71571 11.7657 4.56569C11.9157 4.41566 12 4.21217 12 4V3.2H13.6C13.8122 3.2 14.0157 3.28429 14.1657 3.43431C14.3157 3.58434 14.4 3.78783 14.4 4V6.4Z"
                                fill="#0AA1DD"
                              />
                            </svg>
                          </span>
                        )}
                      </button>
                    </DateRangePicker>
                  </div>
                </div>
                {/* Expire At */}
                <div className="w-full flex flex-col">
                  <label
                    htmlFor="user"
                    className="text-[#555] text-sm font-semibold"
                  >
                    Expire Date
                  </label>
                  <div className="relative">
                    <DateRangePicker
                      initialSettings={{
                        startDate: initialStartDate.format("MM/DD/YYYY"),
                        endDate: initialEndDate.format("MM/DD/YYYY"),
                      }}
                      onApply={handleExpDateRangeChange}
                    >
                      <button className="text-gray-700 bg-white font-[500] rounded-md p-2.5 w-full flex justify-between items-center gap-2">
                        {ExpireDate === ""
                          ? "DD-MM-YYYY"
                          : ExpireDate?.replace(",", " to ")}
                        {ExpireDate ? (
                          <span
                            className="h-5 w-5 bg-red-200 text-red-500 rounded-full flex justify-center items-center"
                            onClick={() => setExpireDate("")}
                          >
                            X
                          </span>
                        ) : (
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M13.6 1.6H12V0.8C12 0.587827 11.9157 0.384344 11.7657 0.234315C11.6157 0.0842854 11.4122 0 11.2 0C10.9878 0 10.7843 0.0842854 10.6343 0.234315C10.4843 0.384344 10.4 0.587827 10.4 0.8V1.6H5.6V0.8C5.6 0.587827 5.51571 0.384344 5.36569 0.234315C5.21566 0.0842854 5.01217 0 4.8 0C4.58783 0 4.38434 0.0842854 4.23431 0.234315C4.08429 0.384344 4 0.587827 4 0.8V1.6H2.4C1.76348 1.6 1.15303 1.85286 0.702944 2.30294C0.252856 2.75303 0 3.36348 0 4V13.6C0 14.2365 0.252856 14.847 0.702944 15.2971C1.15303 15.7471 1.76348 16 2.4 16H13.6C14.2365 16 14.847 15.7471 15.2971 15.2971C15.7471 14.847 16 14.2365 16 13.6V4C16 3.36348 15.7471 2.75303 15.2971 2.30294C14.847 1.85286 14.2365 1.6 13.6 1.6ZM14.4 13.6C14.4 13.8122 14.3157 14.0157 14.1657 14.1657C14.0157 14.3157 13.8122 14.4 13.6 14.4H2.4C2.18783 14.4 1.98434 14.3157 1.83431 14.1657C1.68429 14.0157 1.6 13.8122 1.6 13.6V8H14.4V13.6ZM14.4 6.4H1.6V4C1.6 3.78783 1.68429 3.58434 1.83431 3.43431C1.98434 3.28429 2.18783 3.2 2.4 3.2H4V4C4 4.21217 4.08429 4.41566 4.23431 4.56569C4.38434 4.71571 4.58783 4.8 4.8 4.8C5.01217 4.8 5.21566 4.71571 5.36569 4.56569C5.51571 4.41566 5.6 4.21217 5.6 4V3.2H10.4V4C10.4 4.21217 10.4843 4.41566 10.6343 4.56569C10.7843 4.71571 10.9878 4.8 11.2 4.8C11.4122 4.8 11.6157 4.71571 11.7657 4.56569C11.9157 4.41566 12 4.21217 12 4V3.2H13.6C13.8122 3.2 14.0157 3.28429 14.1657 3.43431C14.3157 3.58434 14.4 3.78783 14.4 4V6.4Z"
                                fill="#0AA1DD"
                              />
                            </svg>
                          </span>
                        )}
                      </button>
                    </DateRangePicker>
                  </div>
                </div>
                {/* Status */}
                <div className="w-full flex flex-col">
                  <label
                    htmlFor="user"
                    className="text-[#555] text-sm font-semibold"
                  >
                    Status
                  </label>
                  <Form.Select
                    aria-label="Default select example"
                    style={{
                      outline: "none",
                      borderRadius: 5,
                      border: 0,
                      padding: 9,
                    }}
                    className="ring-0 focus:ring-transparent bg-white border-none rounded-[5px] text-[#A1A1A1]"
                    onChange={(e) => setClientStatus(e.target.value)}
                  >
                    <option defaultChecked value="">
                      All
                    </option>
                    <option value="active" className="text-[#A1A1A1] py-3">
                      Active
                    </option>
                    <option value="disabled" className="text-[#A1A1A1] py-3">
                      Disabled
                    </option>
                    <option value="expired" className="text-[#A1A1A1] py-3">
                      Expired
                    </option>
                  </Form.Select>
                </div>
              </div>
            )}
            {Company && Company.length > 0 ? (
              Company?.map((el, i) => {
                return (
                  <div
                    className="bg-white  p-5 rounded-xl shadow-2xl relative"
                    key={i}
                  >
                    {/* Label */}
                    <div className="absolute top-0 -left-2">
                      <div className="relative">
                        <img
                          src={el.status === "active" ? active : deactive}
                          alt=""
                        />
                        <div
                          className={`absolute ${
                            el.status === "active" ? "left-4" : "left-3"
                          } top-8`}
                        >
                          <p className="text-xs font-semibold text-white">
                            {el.status === "active"
                              ? "Active"
                              : el.status === "expired"
                              ? "Expired"
                              : "Disabled"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
                      {/* Image */}
                      <div className="flex justify-center items-center bg-slate-100 h-full w-full">
                        <img
                          src={el.logoUrl !== null ? el.logoUrl : companyLogo}
                          alt="Company Logo"
                          className="h-40 w-full md:w-auto rounded-lg object-scale-down"
                        />
                      </div>
                      {/* Profile */}
                      <div className="flex flex-col gap-2.5 justify-between">
                        {el.Users.length > 0 ? (
                          <Link
                            to={`/${
                              el.Users.length > 0 ? el.Users[0].id : ""
                            }/${
                              el.Users.length > 0 ? el.Users[0].fullname : ""
                            }/client-profile`}
                            className="text-[14px] font-[500] text-[#545454]"
                          >
                            <Tooltip title={`Client Name`} color="#0aa1dd">
                              {el.Users.length > 0
                                ? el.Users[0].fullname
                                : "Not Mentioned"}
                            </Tooltip>
                          </Link>
                        ) : (
                          <h1 className="text-[14px] font-[500] text-[#545454]">
                            <Tooltip title={`Client Name`} color="#0aa1dd">
                              {el.Users.length > 0
                                ? el.Users[0].fullname
                                : "Not Mentioned"}
                            </Tooltip>
                          </h1>
                        )}
                        <h6 className="text-[14px] font-semibold text-[#0aa1dd]">
                          <Tooltip title={`Company Name`} color="#0aa1dd">
                            {el.name}
                          </Tooltip>
                        </h6>
                        <div className="flex gap-2">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M20 12C19.9996 9.8784 19.1566 7.84381 17.6564 6.34361C16.1562 4.84342 14.1216 4.00042 12 4V5.6C13.2657 5.60029 14.5029 5.97571 15.5553 6.67883C16.6077 7.38195 17.4281 8.38122 17.9128 9.5504C18.2345 10.327 18.4 11.1594 18.4 12H20ZM4 10.4V6.4C4 6.18783 4.08429 5.98434 4.23431 5.83431C4.38434 5.68429 4.58783 5.6 4.8 5.6H8.8C9.01217 5.6 9.21566 5.68429 9.36569 5.83431C9.51571 5.98434 9.6 6.18783 9.6 6.4V9.6C9.6 9.81217 9.51571 10.0157 9.36569 10.1657C9.21566 10.3157 9.01217 10.4 8.8 10.4H7.2C7.2 12.0974 7.87428 13.7253 9.07452 14.9255C10.2747 16.1257 11.9026 16.8 13.6 16.8V15.2C13.6 14.9878 13.6843 14.7843 13.8343 14.6343C13.9843 14.4843 14.1878 14.4 14.4 14.4H17.6C17.8122 14.4 18.0157 14.4843 18.1657 14.6343C18.3157 14.7843 18.4 14.9878 18.4 15.2V19.2C18.4 19.4122 18.3157 19.6157 18.1657 19.7657C18.0157 19.9157 17.8122 20 17.6 20H13.6C8.2984 20 4 15.7016 4 10.4Z"
                                fill="#0AA1DD"
                              />
                              <path
                                d="M16.4339 10.1633C16.6754 10.7455 16.7996 11.3697 16.7995 12.0001H15.3595C15.3596 11.5588 15.2728 11.1218 15.104 10.7141C14.9351 10.3064 14.6877 9.93599 14.3756 9.62396C14.0636 9.31193 13.6932 9.06444 13.2855 8.89562C12.8778 8.72681 12.4408 8.63997 11.9995 8.64007V7.20007C12.9488 7.20012 13.8768 7.48167 14.6661 8.00911C15.4554 8.53656 16.0706 9.28621 16.4339 10.1633Z"
                                fill="#0AA1DD"
                              />
                              <rect
                                x="0.489329"
                                y="0.489329"
                                width="23.0213"
                                height="23.0213"
                                rx="4.40396"
                                stroke="#0AA1DD"
                                strokeWidth="0.978658"
                              />
                            </svg>
                          </div>
                          <h6 className="text-[14px] font-[500] text-[#000]">
                            <Tooltip title={`Mobile Number`} color="#0aa1dd">
                              {el.Users.length > 0
                                ? el.Users[0].mobileNumber
                                : "Not Mentioned"}
                            </Tooltip>
                          </h6>
                        </div>
                        <div className="flex gap-2">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M20.0005 16.9999V6.99992C20.0005 6.0777 19.3703 5.33325 18.5896 5.33325H5.41142C4.63071 5.33325 4.00049 6.0777 4.00049 6.99992V16.9999C4.00049 17.9221 4.63071 18.6666 5.41142 18.6666H18.5896C19.3703 18.6666 20.0005 17.9221 20.0005 16.9999ZM18.7683 6.8777C19.0787 7.24436 18.9094 7.62214 18.7401 7.81103L14.9211 11.9444L18.5896 16.4555C18.7024 16.611 18.7777 16.8555 18.646 17.0221C18.5237 17.1999 18.2415 17.1888 18.1192 17.0777L14.0087 12.9333L11.9958 15.0999L9.99226 12.9333L5.88173 17.0777C5.75945 17.1888 5.47727 17.1999 5.35499 17.0221C5.2233 16.8555 5.29855 16.611 5.41142 16.4555L9.07985 11.9444L5.26092 7.81103C5.09161 7.62214 4.9223 7.24436 5.2327 6.8777C5.54311 6.51103 5.86292 6.68881 6.1263 6.95547L11.9958 12.5555L17.8747 6.95547C18.1381 6.68881 18.4579 6.51103 18.7683 6.8777Z"
                                fill="#0AA1DD"
                              />
                              <rect
                                x="0.489329"
                                y="0.489329"
                                width="23.0213"
                                height="23.0213"
                                rx="4.40396"
                                stroke="#0AA1DD"
                                strokeWidth="0.978658"
                              />
                            </svg>
                          </div>
                          <div
                            className="text-[#000]  cursor-pointer hidden md:hidden lg:flex items-center "
                            onClick={() => handleCopyClick(el.Users[0]?.email)}
                          >
                            <h1 className="text-[14px] truncate w-48">
                              <Tooltip
                                title={`Email: ${el.Users[0]?.email}`}
                                color="#0aa1dd"
                                placement="top"
                                style={{ paddingBottom: "" }}
                              >
                                <div
                                  className="mb-2 truncate"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {el.Users.length > 0
                                    ? el.Users[0]?.email
                                    : "Not Mentioned"}
                                </div>
                              </Tooltip>
                            </h1>
                          </div>
                          <h6 className="text-[14px] font-[400] text-[#000] block md:block lg:hidden">
                            <Tooltip
                              title={`Email: ${el.Users[0]?.email}`}
                              color="#0aa1dd"
                              placement="top"
                              style={{ paddingBottom: "" }}
                            >
                              {el.Users.length > 0
                                ? el.Users[0]?.email
                                : "Not Mentioned"}
                            </Tooltip>
                          </h6>
                        </div>
                      </div>
                      {/* Address */}
                      <div className="flex flex-col gap-3 justify-between">
                        <div className="">
                          <h6 className="text-[14px] font-[500] text-[#545454]">
                            Address
                          </h6>
                          <p className="text-[12px] w-48">{el.address}</p>
                        </div>
                        <div>
                          <div className="flex item-center gap-2 mb-1">
                            <h6 className="text-[14px] font-semibold text-[#000]">
                              Start Date :
                            </h6>
                            <p className="text-[14px] font-[400] text-green-500">
                              {moment(el.subscriptionStartDate).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                          <div className="flex item-center gap-2 mb-1">
                            <h6 className="text-[14px] font-semibold text-[#000]">
                              Expire Date :
                            </h6>
                            <p className="text-[14px] font-[400] text-green-500">
                              {moment(el.subscriptionEndDate).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* Details */}
                      <div className="flex flex-col justify-between gap-2">
                        <div className="flex flex-col justify-between gap-3">
                          <div className="flex justify-between item-center gap-1">
                            {el.Users.length > 0 ? (
                              <Link
                                to={`/${el.Users.length > 0 ? el.id : ""}/${
                                  el.Users.length > 0
                                    ? el.Users[0]?.fullname
                                    : ""
                                }/client-users`}
                                className="flex items-center gap-1 hover:underline  "
                              >
                                Number of Users
                                <div></div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="11"
                                  height="11"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M4.5 2.25C3.90326 2.25 3.33097 2.48705 2.90901 2.90901C2.48705 3.33097 2.25 3.90326 2.25 4.5V13.5C2.25 14.0967 2.48705 14.669 2.90901 15.091C3.33097 15.5129 3.90326 15.75 4.5 15.75H13.5C14.0967 15.75 14.669 15.5129 15.091 15.091C15.5129 14.669 15.75 14.0967 15.75 13.5V11.25C15.75 10.9516 15.8685 10.6655 16.0795 10.4545C16.2905 10.2435 16.5766 10.125 16.875 10.125C17.1734 10.125 17.4595 10.2435 17.6705 10.4545C17.8815 10.6655 18 10.9516 18 11.25V13.5C18 14.6935 17.5259 15.8381 16.682 16.682C15.8381 17.5259 14.6935 18 13.5 18H4.5C3.30653 18 2.16193 17.5259 1.31802 16.682C0.474106 15.8381 0 14.6935 0 13.5V4.5C0 3.30653 0.474106 2.16193 1.31802 1.31802C2.16193 0.474106 3.30653 0 4.5 0H6.75C7.04837 0 7.33452 0.118527 7.5455 0.329505C7.75647 0.540484 7.875 0.826631 7.875 1.125C7.875 1.42337 7.75647 1.70952 7.5455 1.92049C7.33452 2.13147 7.04837 2.25 6.75 2.25H4.5ZM11.25 2.25C10.9516 2.25 10.6655 2.13147 10.4545 1.92049C10.2435 1.70952 10.125 1.42337 10.125 1.125C10.125 0.826631 10.2435 0.540484 10.4545 0.329505C10.6655 0.118527 10.9516 0 11.25 0H16.875C17.1734 0 17.4595 0.118527 17.6705 0.329505C17.8815 0.540484 18 0.826631 18 1.125V6.75C18 7.04837 17.8815 7.33452 17.6705 7.5455C17.4595 7.75647 17.1734 7.875 16.875 7.875C16.5766 7.875 16.2905 7.75647 16.0795 7.5455C15.8685 7.33452 15.75 7.04837 15.75 6.75V3.84075L12.0465 7.5465C11.9419 7.6511 11.8177 7.73407 11.6811 7.79068C11.5444 7.84729 11.3979 7.87642 11.25 7.87642C11.1021 7.87642 10.9556 7.84729 10.8189 7.79068C10.6823 7.73407 10.5581 7.6511 10.4535 7.5465C10.3489 7.4419 10.2659 7.31773 10.2093 7.18106C10.1527 7.0444 10.1236 6.89792 10.1236 6.75C10.1236 6.60208 10.1527 6.4556 10.2093 6.31894C10.2659 6.18227 10.3489 6.0581 10.4535 5.9535L14.1593 2.25H11.25Z"
                                    fill="#0aa1dd"
                                  />
                                </svg>
                              </Link>
                            ) : (
                              <p className="">Number of Users</p>
                            )}
                            <p>
                              {el.usersCount}/{noOfUsers[i]}
                            </p>
                            {/* button */}
                            <div className="flex justify-around items-center gap-3">
                              <button
                                disabled={
                                  noOfUsers[i] === el.usersCount ||
                                  (NOULoaderM.status === true &&
                                    NOULoaderM.id === el.id)
                                }
                                onClick={() => {
                                  handleUpdateMinus(el.id, noOfUsers[i]);
                                }}
                                className="h-6 w-[19px] flex justify-center disabled:cursor-not-allowed items-center  outline-none bg-slate-100  rounded-xl"
                              >
                                {/* {console.log(NOULoaderM)} */}
                                {NOULoaderM.status === true &&
                                NOULoaderM.id === el.id ? (
                                  <>
                                    <Spinner
                                      animation="border"
                                      variant="primary"
                                      size="sm"
                                    />
                                  </>
                                ) : (
                                  <img
                                    src={Minus}
                                    style={{ height: "15px", width: "15px" }}
                                  ></img>
                                )}
                              </button>
                              <button
                                onClick={() => {
                                  handleUpdatePlus(el.id, noOfUsers[i]);
                                }}
                                className="h-6 w-[19px] flex justify-center  items-center  outline-none bg-slate-100  rounded-xl"
                                disabled={
                                  NOULoaderM.status === true &&
                                  NOULoaderM.id === el.id
                                }
                              >
                                {NOULoaderP.status === true &&
                                NOULoaderP.id === el.id ? (
                                  <Spinner
                                    animation="border"
                                    variant="primary"
                                    size="sm"
                                  />
                                ) : (
                                  <img
                                    src={Plus}
                                    style={{ height: "15px", width: "15px" }}
                                  ></img>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="flex justify-between gap-4 mt-0">
                            <p>Number of Invoices</p>
                            <p>{el?.invoicesCount}</p>
                          </div>
                          <div className="flex justify-between gap-4 mt-0">
                            <Link
                              to={`/${el.id}/${el.Users[0]?.fullname}/clients-customers`}
                              className="flex items-center gap-2 hover:underline"
                            >
                              Number of Customers
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="11"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M4.5 2.25C3.90326 2.25 3.33097 2.48705 2.90901 2.90901C2.48705 3.33097 2.25 3.90326 2.25 4.5V13.5C2.25 14.0967 2.48705 14.669 2.90901 15.091C3.33097 15.5129 3.90326 15.75 4.5 15.75H13.5C14.0967 15.75 14.669 15.5129 15.091 15.091C15.5129 14.669 15.75 14.0967 15.75 13.5V11.25C15.75 10.9516 15.8685 10.6655 16.0795 10.4545C16.2905 10.2435 16.5766 10.125 16.875 10.125C17.1734 10.125 17.4595 10.2435 17.6705 10.4545C17.8815 10.6655 18 10.9516 18 11.25V13.5C18 14.6935 17.5259 15.8381 16.682 16.682C15.8381 17.5259 14.6935 18 13.5 18H4.5C3.30653 18 2.16193 17.5259 1.31802 16.682C0.474106 15.8381 0 14.6935 0 13.5V4.5C0 3.30653 0.474106 2.16193 1.31802 1.31802C2.16193 0.474106 3.30653 0 4.5 0H6.75C7.04837 0 7.33452 0.118527 7.5455 0.329505C7.75647 0.540484 7.875 0.826631 7.875 1.125C7.875 1.42337 7.75647 1.70952 7.5455 1.92049C7.33452 2.13147 7.04837 2.25 6.75 2.25H4.5ZM11.25 2.25C10.9516 2.25 10.6655 2.13147 10.4545 1.92049C10.2435 1.70952 10.125 1.42337 10.125 1.125C10.125 0.826631 10.2435 0.540484 10.4545 0.329505C10.6655 0.118527 10.9516 0 11.25 0H16.875C17.1734 0 17.4595 0.118527 17.6705 0.329505C17.8815 0.540484 18 0.826631 18 1.125V6.75C18 7.04837 17.8815 7.33452 17.6705 7.5455C17.4595 7.75647 17.1734 7.875 16.875 7.875C16.5766 7.875 16.2905 7.75647 16.0795 7.5455C15.8685 7.33452 15.75 7.04837 15.75 6.75V3.84075L12.0465 7.5465C11.9419 7.6511 11.8177 7.73407 11.6811 7.79068C11.5444 7.84729 11.3979 7.87642 11.25 7.87642C11.1021 7.87642 10.9556 7.84729 10.8189 7.79068C10.6823 7.73407 10.5581 7.6511 10.4535 7.5465C10.3489 7.4419 10.2659 7.31773 10.2093 7.18106C10.1527 7.0444 10.1236 6.89792 10.1236 6.75C10.1236 6.60208 10.1527 6.4556 10.2093 6.31894C10.2659 6.18227 10.3489 6.0581 10.4535 5.9535L14.1593 2.25H11.25Z"
                                  fill="#0aa1dd"
                                />
                              </svg>
                            </Link>
                            <p>{el?.customersCount}</p>
                          </div>
                          <div className="flex justify-between gap-4 mt-0">
                            <p>Subscription</p>
                            <p>
                              {el.Subscription?.duration} M /{" "}
                              <span className="font-semibold text-[#0aa1dd]">
                                {el.Subscription?.amount} KWD
                              </span>
                            </p>
                          </div>
                        </div>
                        {/* <div>
                          <p className="text-xs flex justify-between items-center">
                            Validity
                            <span className="">
                              {moment(el.subscriptionEndDate).diff(
                                moment(),
                                "days"
                              ) > 30 ? (
                                <span className="text-green-500">
                                  {moment(el.subscriptionEndDate).diff(
                                    moment(),
                                    "months"
                                  )}{" "}
                                  months left
                                </span>
                              ) : moment(el.subscriptionEndDate).diff(
                                  moment(),
                                  "days"
                                ) >= 10 ? (
                                <span className="text-orange-500">
                                  {moment(el.subscriptionEndDate).diff(
                                    moment(),
                                    "days"
                                  )}{" "}
                                  days left
                                </span>
                              ) : moment(el.subscriptionEndDate).diff(
                                  moment(),
                                  "days"
                                ) >= 0 ? (
                                <span className="text-red-500">
                                  {moment(el.subscriptionEndDate).diff(
                                    moment(),
                                    "days"
                                  )}{" "}
                                  days left
                                </span>
                              ) : (
                                <span className="text-red-500">Expired</span>
                              )}
                            </span>
                          </p>
                          {/* <Progress
                            percent={getBarPercent(el)}
                            strokeColor={twoColors}
                            showInfo={false}
                            className="-mt-1"
                          /> *
                        </div> */}
                      </div>
                      {/* Theme */}
                      <div className="flex flex-col justify-center h-auto ml-4">
                        {/*UserCounteButton*/}

                        <img
                          src={
                            el && el.Theme?.color === "purple"
                              ? purple
                              : el && el.Theme?.color === "red"
                              ? red
                              : el && el.Theme?.color === "green"
                              ? green
                              : el && orange
                          }
                          alt=""
                          className="w-auto md:w-20 xl:w-full"
                        />
                      </div>
                      <div className="absolute top-2 right-2 md:top-5 md:right-5">
                        <Dropdown
                          as="li"
                          className="nav-item dropdown header-profile"
                        >
                          <Dropdown.Toggle
                            variant=""
                            as="a"
                            className="nav-link i-false c-pointer"
                            role="button"
                            data-toggle="dropdown"
                          >
                            <i className="bi bi-three-dots-vertical text-lg"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            align="right"
                            className="dropdown-menu dropdown-menu-end"
                          >
                            <div className="flex flex-col">
                              {el.Invoices?.length > 0 && (
                                <Link
                                  to={`/${el.id}/${el.name}/client-invoices`}
                                  className="text-[#0aa1dd] p-2.5 font-semibold flex items-center gap-2"
                                >
                                  <i className="bi bi-receipt-cutoff"></i>
                                  View Invoices
                                </Link>
                              )}
                              {new Date(el.subscriptionEndDate) < new Date() ? (
                                false
                              ) : (
                                <div className="hover:bg-[#D1D5DB] w-full p-2.5 flex items-center gap-2">
                                  <i
                                    className={`bi bi-person-bounding-box ${
                                      el.status === "active"
                                        ? "text-red-500"
                                        : "text-green-500"
                                    }`}
                                  ></i>
                                  <button
                                    className="text-[#0aa1dd] font-semibold disbaled:cursor-not-allowed disabled:text-gray-300"
                                    onClick={
                                      el.status === "active"
                                        ? () => disableAccount(el.id)
                                        : () => enableAccount(el.id)
                                    }
                                    // disabled=}
                                  >
                                    {el.status === "active" ? (
                                      <span className="text-red-500">
                                        Disable Client
                                      </span>
                                    ) : (
                                      <span className="text-green-500">
                                        Activate Client
                                      </span>
                                    )}
                                  </button>
                                </div>
                              )}
                              {/* {el.status === "expired" && ( */}
                              <Link
                                to={`/${btoa(JSON.stringify(el))}/${
                                  el.name
                                }/renew-client`}
                                className="p-2.5 font-semibold flex items-center gap-2"
                              >
                                <i className="bi bi-arrow-clockwise"></i>
                                Renew Client
                              </Link>
                              <Link
                                to={`/${el.id}/${el.name}/payment-history`}
                                className="p-2.5 font-semibold flex items-center gap-2"
                              >
                                <i className="bi bi-clock-history"></i>
                                Payment History
                              </Link>
                              {/*  )} */}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className=" h-[70vh] flex justify-center items-center">
                <Empty />
              </div>
            )}
          </div>
          <div className="flex justify-center mb-2">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#0aa1dd",
                  fontSize: 14,
                },
              }}
            >
              <Pagination
                defaultCurrent={Page}
                total={Paginate}
                pageSize={Limit}
                className="my-3"
                onChange={handlePaginate}
              />
            </ConfigProvider>
          </div>
        </>
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default Company;
